// Variables
$color: rgb(31, 31, 31);
$speed: "0.2s";
$transition: all #{$speed} cubic-bezier(0.165, 0.84, 0.44, 1);

.tray-page {
  background-color: #1d1d1d;
  width: 100vw;
  height: 100vh;

  display: grid;
  place-items: center;

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100; // Ensure it's below the menu overlay
    display: flex;
    justify-content: center;
    align-items: flex-end; // Align the menu at the bottom

    .menu-overlay {
      background: white;
      width: 430px;
      border-radius: 24px;
      transition: $transition;
      transform: translateY(100%); // Start hidden below the page
      z-index: 102;

      .menu-content {
        padding: 28px;

        h3 {
          margin: 0 0 28px 0;
          padding: 0;
          font-size: 1.1rem;
          line-height: 1.1rem;
        }

        .menu-items {
          border-top: 1px solid rgb(241, 241, 241);
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 16px 0 0 0;

          a.menu-item {
            border-radius: 12px;
            padding: 12px;
            text-decoration: none;
            color: $color;
            font-family: "Inter", sans-serif;
            font-weight: 600;

            background-color: #f7f7f7;

            display: flex;
            align-items: center;
            gap: 12px;

            .menu-item-icon {
              width: 24px;
            }

            &:hover {
              background-color: #eee;
            }
          }
        }
      }
    }

    &.open .menu-overlay {
      transform: translateY(-24px); // Slide up to be visible
    }

    .menu-backdrop {
      position: fixed;
      z-index: 101;
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
    }

    &.closed .menu-backdrop {
      display: none;
    }
  }
}

.tray-open {
  width: 400px;
  height: 300px;
  background-color: rgb(22, 22, 22);
  display: grid;

  place-items: center;
}

.tray-button {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
  display: block;
  background-color: $color;
  height: 64px;
  line-height: 64px;
  outline: none;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #444;
  transition: $transition;
  display: flex;
  flex-wrap: nowrap;
  padding: 0 24px 0 24px;
}
