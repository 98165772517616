// Variables
$color: rgb(31, 31, 31);
$speed: "0.35s";
$transition: all #{$speed} cubic-bezier(0.31, -0.105, 0.43, 1.4);

a.another-button {
  text-decoration: none;
}

.another-button {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 18px;
  display: block;
  background-color: $color;
  height: 64px;
  line-height: 64px;
  margin: auto;
  outline: none;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid #444;
  transition: $transition;
  display: flex;
  flex-wrap: nowrap;
  padding: 0;

  span {
    height: 100%;
    line-height: inherit;
    font-size: 18px;
    font-weight: bold;
    left: 0;
    transition: $transition;
    transform: translateX(-25px);
    z-index: 3;
  }

  .icon {
    z-index: 2;
    width: 30px;
    margin: 7px 0 0 28px;

    transition: $transition;
    transform: scale(0);
  }

  .icon2 {
    z-index: 2;
    width: 40px;
    transition: $transition;
    margin: 7px 0 0 -10px;
  }

  .button-logo {
    filter: invert(99%) sepia(23%) saturate(971%) hue-rotate(198deg)
      brightness(112%) contrast(100%);
    width: 28px;
  }

  .button-more {
    filter: invert(99%) sepia(23%) saturate(971%) hue-rotate(198deg)
      brightness(112%) contrast(100%);
    width: 28px;
  }

  &:hover {
    background-color: #313131;
    span {
      transform: translateX(15px);
    }
    .icon {
      transform: scale(1);
      transition-delay: 0.2s;
    }
    .icon2 {
      transform: scale(0);
      transition-delay: 0.08s;
    }
  }
}
