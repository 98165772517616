:root {
  --primary-color: #302ae6;
  --secondary-color: #aaa;
  --font-color: #575757;
  --bg-color: rgb(249, 249, 249);
  --heading-color: #292929;
}

[data-theme="dark"] {
  --primary-color: #9a97f3;
  --secondary-color: #818cab;
  --font-color: rgb(251, 251, 251);
  --bg-color: #1d1d1d;
  --heading-color: rgb(251, 251, 251);
}

.frontpage {
  background-color: var(--bg-color);
  color: var(--font-color);

  min-height: 100vh;
  width: 100%;
}

.frontpage h1 {
  color: var(--heading-color);
  font-size: 2.5em;
}

.frontpage .inner {
  padding: 40px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
}

.header {
  width: 100%;
  flex: 0 0 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo {
  height: 100%;
}

.theme-switcher {
  height: 100%;
}

.frontpage-main {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.frontpage-content {
  flex: 0 0 1px;
  text-align: center;
}

.frontpage-content-inner {
  text-align: left;
  max-width: 540px;
  margin: 0 auto 0 auto;
}

.frontpage-content-inner a {
  color: var(--font-color);
  text-decoration: none;
}

.playground-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 540px;
  height: 140px;
  background-color: #ffffff08;
  box-shadow: inset 0 0 0 1px #ffffff00, 0 1px 4px #00000030;
  border-radius: 24px;
  transition: 200ms ease-in-out;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  backdrop-filter: blur(2px);
  margin: 0 0 20px 0;

  &:hover {
    transform: scale(1.025);
    backdrop-filter: blur(3px);
    box-shadow: inset 0 0 0 1px #ffffff00, 0 4px 12px 2px #00000030;
  }
}

.playground-card-content {
  margin-left: 40px;
}

.playground-card-title {
  font-size: 1.6em;
  font-weight: 600;
}

.playground-card-description {
  color: var(--secondary-color);
}
