.darkmode-toggle-button {
  label {
    width: 125px;
    height: 50px;
    position: relative;
    display: block;
    background: #ebebeb;
    border-radius: 50px;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.1),
      inset 0px -1px 4px rgba(255, 255, 255, 0.05);
    cursor: pointer;

    &:hover {
      background: #e3e3e3;
    }
  }

  label:after {
    content: "";
    width: 45px;
    height: 45px;
    position: absolute;
    top: 2.5px;
    left: 2.5px;
    background: #ffcc89;
    border-radius: 45px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  }
  input {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  input:checked + label {
    background: #242424;
    &:hover {
      background: #2f2f2f;
    }
  }
  input:checked + label:after {
    left: 122px;
    transform: translateX(-100%);
    background: #3a3a3a;
  }
  label,
  label:after {
    transition: 0.3s;
  }

  label:active:after {
    width: 65px;
  }

  label svg {
    position: absolute;
    width: 30px;
    top: 10px;
    z-index: 100;
  }
  label svg.sun {
    left: 10px;
    transition: 0.3s;
  }
  label svg.sun line {
    stroke: #fff;
  }
  label svg.sun circle {
    stroke: #fff;
  }
  label svg.moon {
    left: 85px;
    transition: 0.3s;
  }
  label svg.moon path {
    stroke: #c9c9c9;
  }
  input:checked + label svg.sun line {
    stroke: #6f6f6f;
  }
  input:checked + label svg.sun circle {
    stroke: #6f6f6f;
  }
  input:checked + label svg.moon path {
    stroke: #fff;
  }
}
