.button-page {
  background-color: #fff;
  width: 100vw;
  height: 100vh;

  display: grid;
  justify-content: center;
}

.button-page h1 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 120px;
  line-height: 1.15;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.8);
  -webkit-font-smoothing: antialiased;
}
